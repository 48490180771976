import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

export default function AboutPage({ data }) {
  return (
    <Layout>
      <SEO title="About" />

      <Img fluid={data.brainImage.childImageSharp.fluid} />

      <h2>Behind The Scenes</h2>

      <h3>Apps for teachers and speakers, made by a teacher.</h3>

      <p>
        I taught High School Science and Computer Science for 5 years in 3
        schools and two states. Teaching is a demanding profession, and thank
        you for your hard work helping your students.
      </p>

      <p>
        Teachers have particular needs for apps. I was generally frustrated at
        the programs I tried to use in my classroom. Many were good, but were
        too complicated, or didn't quite fit the class environment. I am also
        available for iOS contracts. I specialize in implementing UI elements.
        If you have (or are) a designer and need something implemented, I can
        help.
      </p>

      <p>
        Follow us <a href="https://twitter.com/LearningObj">@LearningObj</a>.
        Questions and improvements, email at
        <a href="mailto:brian@learningobjective.net">
          brian@learningobjective.net.
        </a>
      </p>
    </Layout>
  );
}

export const query = graphql`
  query {
    brainImage: file(relativePath: { eq: "brain.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
